html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}

:root {
  --cookie-bar-color-background: #002b53;
  --cookie-bar-color-text: #F7F9FB;
  --cookie-bar-color-header-line: #00ab16;
  --cookie-bar-color-button-highlighted-bg: #00ab16;
  --cookie-bar-color-button-highlighted-fg: #FFF;
  --cookie-bar-color-button-highlighted-border: #00ab16;
  --cookie-bar-color-button-highlighted-hover-bg: #00780f;
  --cookie-bar-color-button-highlighted-hover-fg: #FFF;
  --cookie-bar-color-button-highlighted-hover-border: #00780f;
  --cookie-bar-color-button-transparent-bg: transparent;
  --cookie-bar-color-button-transparent-fg: #00ab16;
  --cookie-bar-color-button-transparent-border: #00ab16;
  --cookie-bar-color-button-transparent-hover-bg: #00780f;
  --cookie-bar-color-button-transparent-hover-fg: #FFF;
  --cookie-bar-color-button-transparent-hover-border: #00780f;
}

@media (min-width: 1000px) {
  :root {
    --cookie-bar-media: (min-width:  1000px);
    --cookie-bar-size-height: 483px;
    --cookie-bar-size-width: 840px;
    --cookie-bar-position-top: 66%;
    --cookie-bar-position-left: 50%;
  }
}