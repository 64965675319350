@import "styles/colors";

.easterPlusmania {
  width: 42px;
  height: 47px;
  border-radius: 2px;
  margin-right: 5px;
}

.text {
  text-decoration: none;
  color: $color-green;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  font-size: 19px;
  line-height: 47px;
  text-align: center;
  white-space: nowrap;
}

.plusmaniaIcon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0px 45px;
  >a {
    display: flex;
  }
}

.gift {
  height: 32px;
  width: auto;
}

@media (max-width: 960px) {
  .plusmaniaIcon {
    margin: 0;
  }
}