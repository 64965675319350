@import "styles/colors";

.personalInformation {
  margin-top: 60px;
  font-family: "ClanPro", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: $color-blue;
}

.formContainer {
  box-sizing: border-box;
  padding-left: 98px;
  padding-right: 98px;
  margin-top: 50px;
}

.leftColumn {
  box-sizing: border-box;
  padding-right: 8px;
  width: 50%;
  float: left;
  position: relative;
}

.rightColumn {
  box-sizing: border-box;
  padding-left: 8px;
  width: 50%;
  float: right;
  position: relative;
}

.cityAndZipContainer {
  box-sizing: border-box;
  min-height: 95px;
}

.cityAndZipLeftColumn {
  box-sizing: border-box;
  width: 75%;
  float: left;
  position: relative;
  padding-right: 8px;
}

.cityAndZipRightColumn {
  box-sizing: border-box;
  width: 25%;
  float: right;
  padding-left: 8px;
  position: relative;
}

.submitContainer {
  padding-left: 98px;
  margin-top: 25px;

  >input {
    width: 140px;
    height: 51px;
    background-color: $color-blue;
    border-radius: 4px;
    border-color: rgba(0,0,0,0);
    color: #ffffff;
    font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    cursor: pointer;
  }

  >input:hover {
    background-color: $color-green;
  }
}

.rules {
  display: inline-block;
  margin: 0px 0 15px 0;
  padding-left: 98px;
  font-size: 14px;
  font-family: "Clan Offc Pro", Arial, Helvetica, sans-serif;
  line-height: 16px;
  color: $color-consentText;
  >a {
    color: $color-blue;
    &:hover {
      color: $color-blue;
    }
  }
}

@media (max-width: 1023px) {
  .formContainer {
    padding-left: 15px;
    padding-right: 15px;
  }

  .leftColumn {
    width: 100%;
    padding-right: 0;
  }

  .rightColumn {
    width: 100%;
    padding-left: 0;
  }

  .submitContainer {
    margin-bottom: 75px;
    display: flex;
    justify-content: center;
    padding: 0;
  }

  .rules {
    margin: 0;
    padding-left: 5%;
  }

  .cityAndZipLeftColumn {
    width: 100%;
    padding: 0;
    float: none;
  }

  .cityAndZipRightColumn {
    width: 100%;
    padding: 0;
    float: none;
  }
}