@import "styles/colors";

.navbarContainer {
  max-height: 70px;
  width: 100%;
  transition: all 0.2s linear;
}

.logo {
  background-color: $color-blue;
  padding-top: 5px;
  width: 85px;
  height: 65px;
  display: inline-flex;
  justify-content: center;
  flex-shrink: 0;

  >img {
    height: 60px;
    width: auto;
  }
}

.navigation {
  font-family: "roboto condensed",verdana,geneva,sans-serif;
  font-size: 0.875rem;
  line-height: 1.875rem;
  margin: 0.375rem;
  position: relative;
  display: flex;
  justify-content: space-around;
  >div>a {
    margin: 0px 6px;
    text-decoration: none;
    text-transform: uppercase;
    color: $color-navigation;
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: pre;
  }
  >div a:first-of-type {
    margin-left: 0;
  }
  >div a:last-of-type {
    margin-right: 0;
  }
}

.gift {
  height: 32px;
  width: auto;
}

.navbarItemContainer {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0px 5px 0px 5px;
}

.articleLead {
  background-color: $color-red;
  font-size: 0.75rem;
  line-height: 1.5rem;
  align-items: center;
  color: #ffffff;
  width: 116px;
  text-decoration: none;
  text-align: center;
  white-space: pre;
  padding: 5px 10px;
  max-height: 50%;

  &:hover {
    background-color: $color-blue;
  }
}

.articleLeadSearchActive {
  @extend .articleLead;
  animation-duration: 1.5s;
  animation-name: slideinArticleLead;
}

.articleLeadSearchInactive {
  @extend .articleLead;
  animation-duration: 1.5s;
  animation-name: slideoutArticleLead;
}

@keyframes slideinArticleLead {
  from {
    transform: translate(200px);
  }

  to {
    transform: translate(0px);
  }
}

@keyframes slideoutArticleLead {
  from {
    transform: translate(-200px);
  }

  to {
    transform: translate(0px);
  }
}

.searchButton {
  width: 36px;
  height: 36px;
  box-shadow: inset 0 0 0 1px #e7e8ec;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px;
  background: 0 0;
  position: absolute;
  right: 5px;
  cursor: pointer;
}

.links {
  display: inline-flex;
  margin-left: 6px;

  >a:hover {
    color: $color-blue;
  }
}

.burgerMenu {
  display: none;
}

.burgerMenuLinksContainer {
  display: none;
}

.burgerMenuContainer {
  display: none;
  align-items: center;
  justify-content: space-around;
}

@media (max-width: 960px) {
  .navbarItemContainer {
    display: none;
  }
}

@media (max-width: 760px) {
  .navbarContainer {
    display: block;
  }

  .navigation {
    display: flex;
    justify-content: space-between;
  }

  .links {
    display: none;
  }

  .burgerMenuContainer {
    display: flex;
  }

  .burgerMenu {
    display: inline-block;
    background-color: #ffffff;
    border: none;
    cursor: pointer;
  }

  .burgerMenuLinksContainer {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #ffffff;
    width: 250px;
    box-shadow: 0px 4px 5px 2px rgba(0, 0, 0, 0.06);
    padding-left: 12px;
    height: 100vh;
    z-index: 5;

    >a {
      text-decoration: none;
      text-transform: uppercase;
      color: $color-navigation;
      display: flex;
      flex-direction: column;
      justify-content: center;
      white-space: pre;
      cursor: pointer;
      margin-bottom: 10px;
    }
  }
}