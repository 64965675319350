@import "styles/colors";

.codeInput {
  margin-top: 44px;

  >div>div>input {
    background-color: rgba(0, 0, 0, 0);
    border-left: none;
    border-top: none;
    border-right: none;
    border-radius: 0;
    font-family: "ClanPro", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 52px;
    height: 50px;
    width: 10%;
    max-width: 65px;
    line-height: 66px;
    text-transform: uppercase;
    border-bottom-color: $color-blue;
    border-bottom-width: 4px;
    margin: 0;
    padding: 0;
    white-space: nowrap;
  }

  >div>div {
    display: flex;
    justify-content: space-evenly;
    padding: 0 10px;
  }

  >div>div>input:focus-visible {
    outline: none;
  }
}

@media (max-width: 1023px) {
  .codeInput {
    >div>div>input {
      font-size: 30px;
      line-height: 32px;
    }
  }
}