@import "styles/colors";

.giveAwayBox {
  margin: 45px 13px 50px 13px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 60% 40%;
}

.textBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .giveAwaySum {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: 100%;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 46px;
    line-height: 54px;
    text-transform: uppercase;
    text-align: center;
    color: $color-green;
    white-space: break-spaces;

    >span {
      white-space: nowrap;
    }

    >.playText {
      color: $color-blue;
      >span {
        white-space: nowrap;
      }
    }

  }

  .giveAwayDaily {
    margin: 10px ;
    color: $color-black;
    font-family: ClanPro, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 26px;
    text-align: center;

    >span {
      white-space: nowrap;
    }
  }
}

.sendCodeButton {
  padding: 16px 30px;
  width: 124px;
  height: 50px;
  margin: 0 auto;
  background-color: $color-green;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: rgba(0,0,0,0);
  cursor: pointer;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  white-space: pre;

  &:hover {
    background-color: $color-blue;
  }
}

.videoBlock {
  height: 100%;
}

.video {
  position: relative;
  width: 100%;
  height: auto;
  border-radius: 22px;
}

.zajo {
  background-image: url("/img/zajo2.webp");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  height: 350px;
}

@media (max-width: 500px) {
  .giveAwaySum {
    >span {
      white-space: initial !important;
    }
  }
}

@media (max-width: 960px) {
  .giveAwayBox {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 1023px) {
   .textBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .giveAwaySum {
      margin-left: 0;
      margin-right: 0;
      font-size: 45px;
    }
  }

  .sendCodeButton {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .videoBlock {
    width: 100%;
  }
}