@import "styles/colors";

.personalInformationInput {
  box-sizing: border-box;
  padding: 12px;
  height: 48px;
  width: 100%;
  background-color: $color-inputBackground;
  border-radius: 4px;
  font-family: "Clan Offc Pro", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #000000;
  border-color: rgba(0,0,0,0);
  margin-bottom: 47px;

  &:focus-visible {
    outline: rgba(0,0,0,0);
    border-color: $color-blue;
  }
}

.personalInformationLabel {
  color: $color-textSecondary;
  font-family: "Clan Offc Pro", Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 21px;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 15px;
  transition: all 0.2s ease;
}

.personalInformationLabelActive {
  @extend .personalInformationLabel;
  top: -20px;
  left: 3px;
}

.required {
  position: absolute;
  left: 5px;
  top: 55px;
  color: $color-red;
  font-family: "Clan Offc Pro", Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.extendedRequired {
  @extend .required;
}


@media (min-width: 1024px) {
  .extendedRequired {
    width: 200%;
  }
}

