.layout {
  box-shadow: 0px 4px 5px 2px rgba(0, 0, 0, 0.06);
  margin: 0px auto;
  max-width: 1024px;
}

.layoutFlexbox {
}

.preloadedImages {
  width: 0px;
  height: 0px;
  display: inline;

  &::after{
    position:absolute; width:0; height:0; overflow:hidden; z-index:-1; // hide images
    content:url('/svg/checkboxActive.svg') url('/svg/checkboxInctive.svg') url('/img/zajo.webp');   // load images
  }
}

@media (max-width: 1023px) {
  .layout {
    margin: 0;
    max-width: 100%;
  }

  .layoutFlexbox {
    display: block;
  }
}