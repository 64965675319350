@import "styles/colors";

.codeFormContainer {
  padding-bottom: 138px;
  position: relative;
  min-height: 440px;
  background-image: url("/img/background.webp");
  background-color: #EDEFF5;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
}

.enterCodeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 33%;
  padding-top: 80px;
  font-family: "Roboto", sans-serif;
}

.enterCode {
  color: $color-blue;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 100%;
  margin-bottom: 16px;
}

.continueButton {
  background-color: $color-blue;
  width: 258px;
  height: 51px;
  border-radius: 4px;
  border-color: rgba(0,0,0,0);
  margin-top: 44px;
  margin-bottom: 60px;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;

  &:hover {
    background-color: $color-green;
  }
}

.thanksForEntering {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  line-height: 46px;
  font-size: 36px;
  text-align: center;
  color: $color-blue;
}

.contact {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: $color-subscribe;
  margin-top: 16px;
}

.enterNewCodeButton {
  padding: 16px 30px;
  background-color: $color-blue;
  border-radius: 4px;
  border-color: rgba(0,0,0,0);
  color: #ffffff;
  margin-top: 25px;
  margin-bottom: 200px;
  cursor: pointer;
  min-height: 51px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  white-space: pre;
  z-index: 10;

  &:hover {
    background-color: $color-green;
  }
}

.zajo {
  position: absolute;
  bottom: 0;
}

.gift {
  position: absolute;
  bottom: -100px;
  right: 40px;
}

.uniqueCodeText {
  padding: 0;
  text-align: center;
  white-space: nowrap;
  font-family: Roboto, sans-serif;
  font-style: italic;
  font-weight: 100;
  color: $color-subscribe;
}

.wrongCodePopup {
  position: fixed;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0px 4px 5px 2px rgba(0, 0, 0, 0.06);
  width: 25vw;
  justify-content: space-evenly;
  align-items: center;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  min-width: 250px;
  min-height: 120px;
  padding: 20px 0;

  >span {
    text-align: center;
    font-family: "Clan Offc Pro", Arial, Helvetica, sans-serif;
    padding: 0 10px;
    font-size: 18px;
  }

  >button {
    background-color: $color-blue;
    border: none;
    border-radius: 4px;
    width: 5vw;
    height: 24px;
    color: #ffffff;
    cursor: pointer;
    min-width: 25%;
    min-height: 35px;

    &:hover {
      background-color: $color-green;
    }
  }
}

.popupBackground {
  background-color: rgba(255, 255, 255, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}

.link {
  color: $color-blue;
  cursor: pointer;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.winnersLink {
  background-color: $color-blue;
  border: none;
  border-radius: 4px;
  width: 5vw;
  height: 24px;
  color: #ffffff;
  cursor: pointer;
  min-width: 200px;
  min-height: 50px;
  text-align: center;
  line-height: 50px;
  text-decoration: none;
  font-weight: bold;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
}

.finished {
  height: 500px;
  padding: 50px 20px 2px 20px;
  line-height: 1.25;

  div {
    line-height: 1.25;
    text-align: center;
  }
}

@media (max-width: 1023px) {
  .gift {
    position: absolute;
    bottom: -105px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 320px;
    height: auto;
  }

  .thanksForEntering {
    padding: 0 15px;
    font-size: 26px;
  }

  .contact {
    padding: 0 30px;
  }

  .enterNewCodeButton {
    margin-bottom: 280px;
    cursor: pointer;
  }

  .finished {
    br {
      display: none;
    }
  }
}

@media (min-width: 374px) {
  .uniqueCodeText {
    >br:first-child {
      display: none;
    }
  }
}

@media (min-width: 1024px) {
  .uniqueCodeText {
    font-size: 24px;
    >br {
      display: none;
    }
  }
}