@import "styles/colors";

.consentContainer {
  padding-left: 98px;
  width: 75%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px;
  font-family: "Clan Offc Pro", Arial, Helvetica, sans-serif;
  line-height: 16px;
  color: $color-consentText;
  margin-top: 15px;

  >input {
    visibility: hidden;
    display: block;
    height: 0;
    width: 0;
    position: absolute;
    overflow: hidden;
  }

  >span {/* <-- Style the artificial checkbox */
    height: 20px;
    width: 20px;
    position: absolute;
    background-image: url("/svg/checkboxInactive.svg");
    background-repeat: no-repeat;
    cursor: pointer;
  }

  >[type=checkbox]:checked + span {/* <-- Style its checked state */
    background-image: url("/svg/checkboxActive.svg");
  }

  >label {
    padding-left: 40px;
    display: inline-block;
    padding-bottom: 14px;
    position: relative;
    cursor: pointer;

    >p {
      margin: 0;

      >a {
        color: $color-blue;

        &:hover {
          color: $color-blue;
        }
      }
    }
  }
}

.required {
  position: absolute;
  left: 38px;
  bottom: -5px;
  white-space: pre;
  color: $color-red;
  font-family: "Clan Offc Pro", Arial, Helvetica, sans-serif;
}

@media (max-width: 1023px) {
  .consentContainer {
    padding-left: 25px;
  }
}