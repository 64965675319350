@font-face {
  font-family: 'ClanPro';
  src: url('/font/ClanPro-Black.eot');
  src: local('/font/ClanPro-Black'),
  url('/font/ClanPro-Black.eot?#iefix') format('embedded-opentype'),
  url('/font/ClanPro-Black.woff2') format('woff2'),
  url('/font/ClanPro-Black.woff') format('woff'),
  url('/font/ClanPro-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Clan Offc Pro';
  src: url('/font/ClanOffcPro-Bold.eot');
  src: local('/font/Clan Offc Pro Bold'), local('ClanOffcPro-Bold'),
  url('/font/ClanOffcPro-Bold.eot?#iefix') format('embedded-opentype'),
  url('/font/ClanOffcPro-Bold.woff2') format('woff2'),
  url('/font/ClanOffcPro-Bold.woff') format('woff'),
  url('/font/ClanOffcPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FontAwesome';
  src: url('/font/FontAwesome.eot');
  src: local('/font/FontAwesome'),
  url('/font/FontAwesome.eot?#iefix') format('embedded-opentype'),
  url('/font/FontAwesome.woff2') format('woff2'),
  url('/font/FontAwesome.woff') format('woff'),
  url('/font/FontAwesome.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Clan Offc Pro';
  src: url('/font/ClanOffcPro-News.eot');
  src: local('/font/Clan Offc Pro'), local('/font/ClanOffcPro-News'),
  url('/font/ClanOffcPro-News.eot?#iefix') format('embedded-opentype'),
  url('/font/ClanOffcPro-News.woff2') format('woff2'),
  url('/font/ClanOffcPro-News.woff') format('woff'),
  url('/font/ClanOffcPro-News.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ClanPro-CondBold';
  src: url('/font/ClanPro-CondBold.eot');
  src: local('/font/ClanPro-CondBold'),
  url('/font/ClanPro-CondBold.eot?#iefix') format('embedded-opentype'),
  url('/font/ClanPro-CondBold.woff2') format('woff2'),
  url('/font/ClanPro-CondBold.woff') format('woff'),
  url('/font/ClanPro-CondBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ClanPro-CondBook';
  src: url('/font/ClanPro-CondBook.eot');
  src: local('/font/ClanPro-CondBook'),
  url('/font/ClanPro-CondBook.eot?#iefix') format('embedded-opentype'),
  url('/font/ClanPro-CondBook.woff2') format('woff2'),
  url('/font/ClanPro-CondBook.woff') format('woff'),
  url('/font/ClanPro-CondBook.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Condensed';
  src: local('/font/Roboto Condensed Light Italic'), local('/font/RobotoCondensed-LightItalic'),
  url('/font/RobotoCondensed-LightItalic.woff2') format('woff2'),
  url('/font/RobotoCondensed-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Condensed';
  src: local('/font/Roboto Condensed Italic'), local('/font/RobotoCondensed-Italic'),
  url('/font/RobotoCondensed-Italic.woff2') format('woff2'),
  url('/font/RobotoCondensed-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Condensed';
  src: local('/font/Roboto Condensed Bold Italic'), local('/font/RobotoCondensed-BoldItalic'),
  url('/font/RobotoCondensed-BoldItalic.woff2') format('woff2'),
  url('/font/RobotoCondensed-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Condensed';
  src: local('/font/Roboto Condensed Bold'), local('/font/RobotoCondensed-Bold'),
  url('/font/RobotoCondensed-Bold.woff2') format('woff2'),
  url('/font/RobotoCondensed-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Condensed';
  src: local('/font/Roboto Condensed Light'), local('/font/RobotoCondensed-Light'),
  url('/font/RobotoCondensed-Light.woff2') format('woff2'),
  url('/font/RobotoCondensed-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Condensed';
  src: local('/font/Roboto Condensed'), local('/font/RobotoCondensed-Regular'),
  url('/font/RobotoCondensed-Regular.woff2') format('woff2'),
  url('/font/RobotoCondensed-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@font-face {
  font-family: 'Roboto';
  src: local('/font/Roboto Light Italic'), local('/font/Roboto-LightItalic'),
  url('/font/Roboto-LightItalic.woff2') format('woff2'),
  url('/font/Roboto-LightItalic.woff') format('woff'),
  url('/font/Roboto-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('/font/Roboto Bold Italic'), local('/font/Roboto-BoldItalic'),
  url('/font/Roboto-BoldItalic.woff2') format('woff2'),
  url('/font/Roboto-BoldItalic.woff') format('woff'),
  url('/font/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('/font/Roboto Light'), local('/font/Roboto-Light'),
  url('/font/Roboto-Light.woff2') format('woff2'),
  url('/font/Roboto-Light.woff') format('woff'),
  url('/font/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('/font/Roboto Bold'), local('/font/Roboto-Bold'),
  url('/font/Roboto-Bold.woff2') format('woff2'),
  url('/font/Roboto-Bold.woff') format('woff'),
  url('/font/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('/font/Roboto Black'), local('/font/Roboto-Black'),
  url('/font/Roboto-Black.woff2') format('woff2'),
  url('/font/Roboto-Black.woff') format('woff'),
  url('/font/Roboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('/font/Roboto Italic'), local('/font/Roboto-Italic'),
  url('/font/Roboto-Italic.woff2') format('woff2'),
  url('/font/Roboto-Italic.woff') format('woff'),
  url('/font/Roboto-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('/font/Roboto Black Italic'), local('/font/Roboto-BlackItalic'),
  url('/font/Roboto-BlackItalic.woff2') format('woff2'),
  url('/font/Roboto-BlackItalic.woff') format('woff'),
  url('/font/Roboto-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('/font/Roboto Medium'), local('/font/Roboto-Medium'),
  url('/font/Roboto-Medium.woff2') format('woff2'),
  url('/font/Roboto-Medium.woff') format('woff'),
  url('/font/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('/font/Roboto Medium Italic'), local('/font/Roboto-MediumItalic'),
  url('/font/Roboto-MediumItalic.woff2') format('woff2'),
  url('/font/Roboto-MediumItalic.woff') format('woff'),
  url('/font/Roboto-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('/font/Roboto Thin Italic'), local('/font/Roboto-ThinItalic'),
  url('/font/Roboto-ThinItalic.woff2') format('woff2'),
  url('/font/Roboto-ThinItalic.woff') format('woff'),
  url('/font/Roboto-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('/font/Roboto'), local('/font/Roboto-Regular'),
  url('/font/Roboto-Regular.woff2') format('woff2'),
  url('/font/Roboto-Regular.woff') format('woff'),
  url('/font/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Thin'), local('Roboto-Thin'),
  url('/font/Roboto-Thin.woff2') format('woff2'),
  url('/font/Roboto-Thin.woff') format('woff'),
  url('/font/Roboto-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

