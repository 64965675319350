@import "styles/colors";

.footerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 140px;

  >img {
    margin-bottom: 18px;
  }
}

.copyRight {
  max-width: 838px;
  font-family: "Clan Offc Pro", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: $color-copyRightText;
  margin: 0 10px 10px 10px;
}