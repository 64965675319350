$color-blue: #006FB9;
$color-red: #e70033;
$color-navigation: #87929f;
$color-redSecondary: #E12652;
$color-inputBackground: #F8FAFC;
$color-textSecondary: #6F7482;
$color-consentText: #696868;
$color-copyRightText: #5D6064;
$color-subscribe: #454545;
$color-green: #79B100;
$color-black: #2E2C2C;